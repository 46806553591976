import React from 'react'

import Header from './header'
import Footer from './footer'
import '../styles/index.scss'
import CookieConsent from 'react-cookie-consent'

const Layout = ({ title, children }) => {
  return (
    <div className="grid-container">
      <div>
        <Header title={title} />
        {children}
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: '#2B373B' }}
        buttonStyle={{
          color: '#FFF',
          backgroundColor: '#343090',
          fontSize: '16px',
          padding: '10px',
          borderRadius: '5px',
        }}
        expires={150}
      >
        Nous utilisons des cookies pour vous fournir la meilleure expérience
        utilisateur possible sur ce site. <br />
        <span style={{ fontSize: '12px' }}>
          Si vous continuez, nous considérons que vous est d'accord avec ce
          principe.
        </span>
      </CookieConsent>
    </div>
  )
}

export default Layout
