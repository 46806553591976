import React, { useEffect, useRef, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const Header = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <header className="header">
      <div className="header--top full-width bg-primary">
        <div className="grid-container">
          <div className="flex-horizontal">
            <Link className="logo" to="/">
              JEDI
            </Link>
            <h1 className="h3">{title || data.site.siteMetadata.title}</h1>
          </div>
        </div>
      </div>
      <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
        <div className="sticky-inner">
          <nav className="full-width bg-dark">
            <div className="grid-container">
              <ul className="header--nav-list">
                <li>
                  <Link
                    className="header--nav-item"
                    activeClassName="header--nav-item__active"
                    to="/"
                  >
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    className="header--nav-item"
                    activeClassName="header--nav-item__active"
                    to="/testimonials"
                  >
                    Témoignages
                  </Link>
                </li>
                <li>
                  <Link
                    className="header--nav-item"
                    activeClassName="header--nav-item__active"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    className="header--nav-item"
                    activeClassName="header--nav-item__active"
                    to="/about"
                  >
                    À propos
                  </Link>
                </li>
                <li>
                  <Link
                    className="header--nav-item"
                    activeClassName="header--nav-item__active"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
