import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({ title, description, image }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  return (
    <Helmet title={`${title}`}>
      <html lang="fr-FR" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="referrer" content="origin" />
      <link rel="canonical" href="https://developpeur-web-strasbourg.fr/" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content={description || `${title} | ${data.site.siteMetadata.title}`}
      />
      <meta
        property="og:description"
        content={description || `${title} | ${data.site.siteMetadata.title}`}
      />
      <meta
        property="og:image"
        content={`${data.site.siteMetadata.siteUrl}${image ||
          '/default-image.jpg'}`}
      />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title}`} />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
    </Helmet>
  )
}

export default Head
