import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className="footer footer__bottom full-width bg-dark">
      <div className="grid-container">
        <div className="grid-x align-center-middle">
          <a
            className="cell small-12 medium-shrink footer--link"
            href="http://jeromedicharry.com/mentions-legales/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mentions légales -
          </a>
          <a
            className="cell small-12 medium-shrink footer--link"
            href="http://jeromedicharry.com/politique-de-confidentialite//"
            target="_blank"
            rel="noopener noreferrer"
          >
            Politique de confidentialité -
          </a>
          <p className="mb-0 copyright">
            Tous droits réservés {data.site.siteMetadata.author}, ©{' '}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
